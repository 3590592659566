/* Mixins */
.sign-component {
  height: 100vh;
  width: 100%;
}
.sign-component .bg {
  min-height: 600px;
  min-width: 1000px;
  height: 100vh;
  width: 100%;
  background-size: cover;
  overflow: hidden;
}
.sign-component .version {
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: #fff;
  background: var(--primaryColor);
  padding: 2px 8px;
  opacity: 0.6;
}
.sign-component .title {
  position: absolute;
  top: 5%;
  left: 40px;
  color: #FAFAFA;
  font-weight: bolder;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sign-component .title .logo {
  margin-right: 12px;
  margin-top: -8px;
  max-height: 40px;
}
.sign-component .title .divider {
  margin-right: 12px;
  height: 31px;
  opacity: 0.3;
}